import React from 'react';
import "../assets/styles/_index.scss"
import { ArrowLeft, ArrowRight, CurrencyRupee, GeoAlt } from 'react-bootstrap-icons'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from "gatsby"

import prevArrow from '/utility/assets/icons/prevArrow.png'
import nextArrow from '/utility/assets/icons/nextArrow.png'

import Brochureimage from "/utility/assets/common/details/brochure-image.png";
import Floorplanimage from "/utility/assets/common/details/floor-plan-image.png";
import Projectpriceimage from "/utility/assets/common/details/project-price-image.png";
import Projectvideoimage from "/utility/assets/common/details/project-video-image.png";
import PropTVimage from "/utility/assets/common/prop-tv-image.png";

import ShobaGaleraLogo from "/utility/assets/sobha-developer/our-properties/Sobha-Galera.png";

import VideoPlayerTv from "../../../videoplayerTv/VideoPlayerTv"
import ImageGalleryModal from "../../../ImageGalleryModal/ImageGalleryModal"

const videoUrl ="https://www.propverse.ai/utility/assets/sobha-developer/SobhaGalera/SOBHAGalera.mp4"
const thumbnailUrl = require("/utility/assets/common/prop-tv-image.png")

const CustomPrevArrow = (props) => {
  
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${prevArrow})` }}
      onClick={onClick}
    />
  );
}

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '5px', height: '5px', backgroundImage: `url(${nextArrow})` }}
      onClick={onClick}
    />
  );
}

const ShobaGaleraSidebar = ({ isOpen, toggleRightSidebar ,id}) => {
  const handleFloorViewButtonClick = () => {
    window.open("/utility/assets/sobha-developer/SobhaGalera/FloorPlan.pdf")
  }

  const handleFloorDownloadButtonClick = () => {
    const imageUrl = "/utility/assets/sobha-developer/SobhaGalera/FloorPlan.pdf"
    const anchor = document.createElement("a")
    anchor.href = imageUrl
    anchor.download = "Floor-Image.pdf"
    anchor.click()
  }

  const handleBrochureViewButtonClick = () => {
    window.open("/utility/assets/sobha-developer/SobhaGalera/SOBHAGALERABrochure.pdf")
  }

  const handleBrochureDownloadButtonClick = () => {
    const pdfUrl = "/utility/assets/sobha-developer/SobhaGalera/SOBHAGALERABrochure.pdf"

    const anchor = document.createElement("a")
    anchor.href = pdfUrl
    anchor.download = "SOBHAGALERABrochure.pdf"
    anchor.click()
  }

  const handleVideoViewButtonClick = () => {
    window.open("/utility/assets/sobha-developer/SobhaGalera/SOBHAGalera.mp4")
  }

  const handleVideoDownloadButtonClick = () => {
    const videoUrl = "/utility/assets/sobha-developer/SobhaGalera/SOBHAGalera.mp4"
    const anchor = document.createElement("a")
    anchor.href = videoUrl
    anchor.download = "SOBHAGalera.mp4"
    anchor.click()
  }

  const handlePriceViewButtonClick = () => {
    window.open("/utility/assets/sobha-developer/SobhaGalera/CostSheet.pdf")
  }

  const handlePriceDownloadButtonClick = () => {
    const imageUrl = "/utility/assets/sobha-developer/SobhaGalera/CostSheet.pdf"
    const anchor = document.createElement("a")
    anchor.href = imageUrl
    anchor.download = "CostSheet.pdf"
    anchor.click()
  }

  const cardItems = [
    {
      id: 0,
      image: Brochureimage,
      title: "Brochure",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/bloom-dell/BirlaBrochure.pdf",
      viewButtonClick: handleBrochureViewButtonClick,
      downloadButtonClick: handleBrochureDownloadButtonClick,
    },
    {
      id: 1,
      image: Projectvideoimage,
      title: "Project Video",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/bloom-dell/BirlaAlokya.mp4",
      viewButtonClick: handleVideoViewButtonClick,
      downloadButtonClick: handleVideoDownloadButtonClick,
    },
    {
      id: 2,
      image: Projectpriceimage,
      title: "Project Price",
      button1: "Download",
      button2: "View",
      BirlaAlokya: "/utility/assets/birla-estates/bloom-dell/PriceList.jpg",
      viewButtonClick: handlePriceViewButtonClick,
      downloadButtonClick: handlePriceDownloadButtonClick,
    },
    {
      id: 3,
      image: Floorplanimage,
      title: "Floor plan",
      button1: "Download",
      button2: "View",
      viewButtonClick: handleFloorViewButtonClick,
      downloadButtonClick: handleFloorDownloadButtonClick,
      BirlaAlokya:
        "/utility/assets/birla-estates/bloom-dell/floor-plan/FloorPlan-0.jpg",
    },
  ]





  const hideButton = id === '5';
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },

    ],

  }

  return (
    <div className={`right-sidebar-details ${isOpen ? 'open' : ''}`}>

      <div className="projectdetailsBackButton" >
        <Container>
          <Row>
            <Col lg={3}>
              <div className='mt-2'>
                <span onClick={toggleRightSidebar}>
                  <Link to="/developers/sobha-developer/"><ArrowLeft /> Back</Link></span>
              </div>
            </Col>
            <Col lg={6}>
              <h5 className='m-0'>Galera</h5>
            </Col>
            <Col lg={3}>
              <Image src={ShobaGaleraLogo} fluid className="headerImage m-0" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="projectdetailslist">
        {cardItems.map((card) => (

          <div >
             
            <Container>
              <Col>
                <Row lg={2}>
                  <h6 className='projectdetailslist-card-title'>{card.title}</h6>
                </Row >
                <Row lg={8}>
                  <Image
                    src={card.image}
                    alt="2"

                  />
                </Row>
                {card.id !== 4 && (
                  <Row lg={2} className='projectdetailslist-card-buttons' >
                    <Button
                      className="card-buttons-download"
                      onClick={card.downloadButtonClick}
                    >
                      {card.button1}
                    </Button>
                    <Button
                      className="card-buttons-view"
                      onClick={card.viewButtonClick}
                    >
                      {card.button2}
                    </Button>
                  </Row>
                )}
              </Col>
            </Container>
            </div>
        ))}
        <div className="projectdetailslist-tv">
          <h6 className="projectdetailslist-tv-title">Prop TV</h6>

          <VideoPlayerTv
            className="projectdetailslist-tv-video"
            videoUrl={videoUrl}
            thumbnailUrl={thumbnailUrl}
          />
        </div>
        <div className='footer-link'>
          <Link target="_blank" to="https://www.propex.ai/projects/np-62579-sobha-galera/colive_ppm_52579">
            <text>Click here for more details<ArrowRight /> </text>
          </Link>
        </div>
      </div>


    </div>
  );
};

export default ShobaGaleraSidebar;
